const brandColors = {
  primary: '#1C407B',
  secondary: '#22333c',
};

const sidebarColors = {
  backgroundColor: '#EFEFEF',
  color: '#22333c',
  dotfileLogo: '#22333c',
};

const linkColors = {
  color: '#13735E',
};

export const tokens = {
  brand: brandColors,
  sidebar: sidebarColors,
  link: linkColors,
};
